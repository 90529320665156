import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';
import SEO from "components/seo/SEO";

export default function Index() {
  const router = useRouter();

  useEffect(() => {
    router.push('/products');
  }, []);

  return (
    <>
      <SEO pageId={"home"} />
    </>
  );
}